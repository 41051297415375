import { FormControl, Checkbox, FormErrorMessage, Text } from '@chakra-ui/react';

export default function FormCheckBox({
  label,
  id,
  isRequired,
  isDisabled,
  isReadOnly,
  placeholder,
  inputProps,
  controlProps,
  error,
  isChecked,
  marginTop,
  fontSize,
  letterSpacing,
}) {
  return (
    <FormControl
      isRequired={isRequired}
      marginRight="20px"
      mb="20px"
      textAlign="left"
      id={id}
      isInvalid={error}
      htmlFor={id}
      {...controlProps}
    >
      <Checkbox
        colorScheme="pink"
        marginTop={marginTop}
        type={'text'}
        borderRadius="0"
        placeholder={placeholder}
        borderColor={'text.secondary'}
        isInvalid={error}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        {...inputProps}
        isChecked={isChecked}
        alignItems="top"
        isRequired={isRequired}
      >
        <Text lineHeight="1.1" fontSize={fontSize} letterSpacing={letterSpacing}>
          {label}
        </Text>
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
