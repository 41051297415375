import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { NumberBox } from './NumberBox';

export const CountdownWrapper = ({ days, hours, minutes, seconds }) => {
  return (
    <Flex align="flex-start" justifyContent="center">
      <Flex align="flex-start">
        <NumberBox prev={0} count={days} label={'days'} />
        <Text color="#620033" margin="0 5px" lineHeight="1" fontSize={{ base: '38px', md: '50px' }} fontWeight="600">
          :
        </Text>
      </Flex>
      <Flex align="flex-start">
        <NumberBox prev={0} count={hours} label={'hours'} />
        <Text color="#620033" margin="0 5px" lineHeight="1" fontSize={{ base: '38px', md: '50px' }} fontWeight="600">
          :
        </Text>
      </Flex>
      <Flex align="flex-start">
        <NumberBox prev={0} count={minutes} label={'minutes'} />
        <Text color="#620033" margin="0 5px" lineHeight="1" fontSize={{ base: '38px', md: '50px' }} fontWeight="600">
          :
        </Text>
      </Flex>
      <Flex align="flex-start">
        <NumberBox prev={0} count={seconds} label={'seconds'} />
      </Flex>
    </Flex>
  );
};
