import React, { useMemo } from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';

export const NumberBox = ({ count, label }) => {
  const padCount = useMemo(() => {
    return String(count).padStart(2, '0');
  }, [count]);
  return (
    <Box>
      <Flex
        color={label === 'seconds' ? 'brand.900' : 'white'}
        w={{ base: '55px', md: '90px' }}
        h={{ base: '45px', md: '60px' }}
        align="center"
        justifyContent="center"
        fontWeight="600"
        transition="0.2s ease"
        position="relative"
      >
        <Box
          position="absolute"
          left="0"
          width="46%"
          pr="2px"
          h="100%"
          borderRadius={{ base: '10px', md: '15px' }}
        ></Box>
        <Box
          position="absolute"
          right="0"
          width="46%"
          pl="2px"
          h="100%"
          borderRadius={{ base: '10px', md: '15px' }}
        ></Box>
        <Text
          fontSize={{ base: '38px', md: '58px' }}
          letterSpacing={{ base: '10px', md: '18px' }}
          lineHeight="1.05"
          height={{ base: '44px', md: '70px' }}
          position="relative"
          zIndex="1"
          ml={{ base: '9px', md: '16px' }}
        >
          {padCount}
        </Text>
      </Flex>
      <Text color="white" textAlign="center" fontSize="12px" textTransform="uppercase" mt="5px" letterSpacing="1px">
        {label}
      </Text>
    </Box>
  );
};
