import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';

const url = apiURL;

export const getWebformData = (locale, webFormID) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/webform_rest/${webFormID}/fields`,
    method: 'GET',
  });
};

export const sendWebformData = (locale, formData) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/webform_rest/submit`,
    method: 'POST',
    data: formData,
  });
};
