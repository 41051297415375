import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { CountdownWrapper } from './CountdownWrapper';

const Countdown = ({ time }) => {
  const [now, setNow] = useState();

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let finalDate = new Date(time).getTime();
  let distance = finalDate - now;

  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return (
    <Box mb="30px" display={distance > 0 ? 'block' : 'none'}>
      <CountdownWrapper days={days} hours={hours} minutes={minutes} seconds={seconds} />
    </Box>
  );
};

export default Countdown;
